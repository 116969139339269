.alert, %alert {
    border: 1px solid black;
    background-color: gray;
    color: black;
    &--green {
        @extend %alert;
        color: #155724;
        border-color: #c3e6cb;
        background-color: #d4edda;
    }
    &--yellow {
        @extend %alert;
        color: #856404;
        border-color: #ffeeba;
        background-color: #fff3cd;
    }
}